import { CF2Component } from 'javascript/lander/runtime'
import { CartData } from 'src/Elements/Cart/types'

export function hasPhysicalProducts(cart: CartData): boolean {
  return cart.items.some(({ product_id, variant_id }) => {
    const product = globalThis.globalResourceData.productsById[product_id]
    const variant = globalThis.globalResourceData.variantsById[variant_id]
    let productType = variant?.product_type
    productType = productType || product?.product_type
    return productType == 'physical'
  })
}

export function setupOrderSummaries(component: CF2Component): void {
  const pageOrderSummaries = document.querySelector('[data-page-element="CheckoutOrderSummary/V1"].elCheckout')
  if (pageOrderSummaries) {
    component.getComponents('CheckoutOrderSummary/V1').forEach((c) => {
      c.element.style.display = 'none'
    })
  }
}
